// ** Reducers Imports
import failedLogins from '@src/views/superadmin/failed-logins/store';
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import admins from '../views/superadmin/user-management/store';
import universities from '../views/apps/universities/store';
import appusers from '../views/apps/users/store';
import passwordManagement from '../views/apps/auth/store';
import modal from './modal';
import arrivalGuide from '../views/apps/universities/components/edit/arrival-guides-new/store';
import feedback from '../views/apps/goin-community/store';
import groups from '../views/apps/universities/components/edit/groups/store';
import worldUniversities from '../views/superadmin/world-universities/store';
import communityQuestions from '../views/apps/universities/components/edit/community-questions/store';
import resources from '../views/superadmin/resources/store';
import activationAnalysis from '../views/apps/activation-analysis/store';
import cycles from '../views/apps/cycles/store';
import universityGroups from '../views/apps/group-explorer/store';
import myProfile from '../views/apps/my-profile/store';

const rootReducer = {
  admins,
  auth,
  appusers,
  universities,
  passwordManagement,
  navbar,
  layout,
  modal,
  arrivalGuide,
  feedback,
  groups,
  failedLogins,
  worldUniversities,
  communityQuestions,
  resources,
  myProfile,
  activationAnalysis,
  cycles,
  universityGroups,
};

export default rootReducer;
