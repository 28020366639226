// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Check, Trash, X } from 'tabler-icons-react';
import Achievement from '@universities/components/edit/gamification/types/Achievement';
import ToastWrapper from '@reusable-components/ToastWrapper';

import University from '@universities/types/University';
import Stats from '@universities/types/Stats';
import Nationalities from '@universities/types/Nationalities';
import CampusesRequest from '@universities/request/CampusesRequest';
import StudiesRequest from '@universities/request/StudiesRequest';
import UniversityRequest from '@universities/request/UniversityRequest';
import Campus from '@universities/types/Campus';
import { GoinScoreCollection } from '@universities/types/GoinScoreStats';
import StatsParams from '@universities/types/StatsParams';
import Study from '@universities/types/Study';
import UniStats from '@universities/types/UniStats';
import ConnectionFilter from '@universities/components/edit/connection-filters/types/ConnectionFilter';

const universitiesGoin:University[] = [];
const searchNationalities: Nationalities [] = [];
const searchScores: GoinScoreCollection [] = [];
const initState: University[] = [];
const initStats: Stats = {} as Stats;
const initUniStats: UniStats = {} as UniStats;
const initAchievements: Achievement[] = [];
const initConnectionFilters: ConnectionFilter[] = [];

const abroadUniversityFilter = ['Home uni', 'Destination uni', 'Destination city'];

export const getUniversities = createAsyncThunk('goin/getUniversities', async () => {
  universitiesGoin.splice(0, universitiesGoin.length);
  Object.assign(universitiesGoin, []);
  const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/dashboard/universities`);
  Object.assign(universitiesGoin, response.data);
  return {
    data: response.data,
  };
});

export const successToast = (title: string) => ToastWrapper(
  'Changes are saved!',
  <>{title}</>,
  <Check size={12} />,
  'success',
);

export const errorToast = (message?:string) => ToastWrapper(
  'Oops!',
  <>
    <b>Something Went Wrong!
      <span role="img" aria-label="emoji">😖</span>
    </b>
    <br />
    <hr />
    {message || 'There seems to be an error, please try again or check back later!'}
  </>,
  <X size={12} />,
  'danger',
);

export const deleteToast = (title: string) => ToastWrapper(
  'Changes are saved!',
  <>{title}</>,
  <Trash size={12} />,
  'warning',
);

export const getUniversityById = createAsyncThunk<{data: University}, string | null, {rejectValue: number}>('goin/getUniversityById', async (_id, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/universities/${_id}`);
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      return rejectWithValue(err?.response?.status as number);
    }
    return rejectWithValue(400);
  }
});

export const addUniversity = createAsyncThunk<{data: University}, University, {rejectValue: number}>('goin/addUniversity', async (university:University, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/universities`, university);
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
      return rejectWithValue(err?.response?.status as number);
    }
    return rejectWithValue(400);
  }
});

export const updateUniversity = async (id: string, values: UniversityRequest) => {
  try {
    return await axios.put(`${process.env.REACT_APP_URL_ENV}/universities/${id}`, values);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateStudy = async (_id: string, values:Study, _studyId: string) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/universities/${_id}/studies/${_studyId}`, values);
    successToast('Changes for study are successfully saved!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

export const updateCampus = async (_id: string, values:Campus, _campusId?: string) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/universities/${_id}/campus/${_campusId}`, values);
    successToast('Changes for campus are successfully saved!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

export const getStudies = createAsyncThunk('goin/getStudies', async (_id: string) => {
  const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/dashboard/${_id}/studies`);
  return {
    data: response.data,
  };
});

export const addStudies = async (_id:string, study:StudiesRequest) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/universities/${_id}/studies`, study);
    successToast('Study successfully added!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(err);
  }
};

export const addCampuses = async (_id:string, campus: CampusesRequest) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/universities/${_id}/campus`, campus);
    successToast('Campus successfully added!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(err);
  }
};

export const deleteStudies = async (_id: string, _studyId: string) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/universities/${_id}/studies/${_studyId}`);
    deleteToast('Study successfuly deleted!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(err);
  }
};

export const deleteCampus = async (_id: string, _campusId?: string) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/universities/${_id}/campus/${_campusId}`);
    deleteToast('Campus successfuly deleted!');
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(err);
  }
};

export const getArrivals = createAsyncThunk('goin/getArrivals', async (_id: string) => {
  const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/universities/${_id}/checklist`);
  return {
    data: response.data,
  };
});

export const updateAuthMethods = createAsyncThunk<{data: University}, University, {rejectValue: number}>('goin/authMethods', async (university: University, { rejectWithValue }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/universities/${university._id}/auth`,
      {
        loginSocial: university.loginSocial,
        loginEmail: university.loginEmail,
        loginAzure: university.loginAzure,
        loginAzureWhitelist: university.loginAzureWhitelist,
        azureRegisterPlaceholder: university.azureRegisterPlaceholder,
        azureLoginPlaceholder: university.azureLoginPlaceholder,
        whitelist: university.whitelist,
        azureProperties: university.azureProperties,
      });
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast();
      return rejectWithValue(err?.response?.status as number);
    }
    return rejectWithValue(400);
  }
});

export const filterData = createAsyncThunk('goin/filterUniversities',
  async (search:string) => (
    {
      filterUniversities:
      universitiesGoin.filter(
        (u: University) => JSON.stringify(u).toLowerCase().includes(search.toLowerCase()),
      ),
    }));

export const filterNationalities = createAsyncThunk('goin/filterNationalities',
  async (search: string) => (
    {
      filterNationalities:
      searchNationalities.filter(
        (n: Nationalities) => JSON.stringify(n).toLowerCase().includes(search.toLowerCase()),
      ),
    }
  ));

export const filterScores = createAsyncThunk('goin/filterScores',
  async (search: string) => (
    {
      filterScores:
      searchScores.filter(
        (s: GoinScoreCollection) => JSON.stringify(s).toLowerCase().includes(search.toLowerCase()),
      ),
    }
  ));

export const getStats = createAsyncThunk('goin/getStats', async (_id: string | null) => {
  Object.assign(searchNationalities, []);
  Object.assign(searchScores, []);
  const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/universities/${_id}/stats/super-admin`);
  Object.assign(searchNationalities, response.data.generalStats.nationalities);
  Object.assign(searchScores, response.data.goinScoreStats.goinScoreCollection);
  return {
    data: response.data,
  };
});

export const getUniAdminStats = createAsyncThunk('goin/getUniAdminStats', async (statsParams:StatsParams) => {
  const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/universities/${statsParams.universityId}/stats`, { params: statsParams.query });
  return {
    data: response.data,
  };
});

export const getPredefinedAchievements = createAsyncThunk('goin/getPredefinedAchievements', async () => {
  const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/dashboard/achievements`);
  return {
    data: response.data,
  };
});

export const getPredefinedConnectionFilters = createAsyncThunk('goin/getPredefinedConnectionFilters', async () => {
  const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/dashboard/connection-filters`);
  return {
    data: response.data,
  };
});

export const uploadPhoto = async (_id: string, config: AxiosRequestConfig, logo?: FormData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/universities/${_id}/upload`, logo, config);
    successToast('Changes for logo image are successfully saved!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

export const updateConnectionFilters = async (
  _id: string,
  connectionFilters: string[],
  enableConnectionFilters: boolean,
) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/universities/${_id}/connection-filters`,
      { connectionFilters, enableConnectionFilters });
    successToast('Changes for connection filters are successfully saved!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

export const universitiesSlice = createSlice({
  name: 'universities',
  initialState: {
    data: initState,
    total: 1,
    currentUniversity: {} as University,
    studies: [],
    arrivals: [],
    achievements: initAchievements,
    connectionFilters: initConnectionFilters,
    stats: initStats,
    uniStats: initUniStats,
  },
  reducers: {
    setCurrentUniversity: (state, action) => {
      state.currentUniversity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUniversities.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.data = state.data.sort((a, b) => (a.name < b.name ? -1 : 1));
    });
    builder.addCase(getStats.fulfilled, (state, action) => {
      state.stats = action.payload.data;
    });
    builder.addCase(getUniAdminStats.fulfilled, (state, action) => {
      state.uniStats = action.payload.data;
    });
    builder.addCase(getPredefinedAchievements.fulfilled, (state, action) => {
      state.achievements = action.payload.data;
    });
    builder.addCase(getPredefinedConnectionFilters.fulfilled, (state, action) => {
      if (state.currentUniversity.isExchange === true) {
        state.connectionFilters = action.payload.data;
      } else {
        state.connectionFilters = action.payload.data.filter(
          (f: ConnectionFilter) => !abroadUniversityFilter.some(
            (abroadFilter) => abroadFilter === f.name,
          ),
        );
      }
    });
    builder.addCase(updateAuthMethods.fulfilled, (state, action) => {
      state.currentUniversity = action.payload.data;
      successToast('Changes for authentication methods are successfully saved!');
    });
    builder.addCase(getUniversityById.fulfilled, (state, action) => {
      state.currentUniversity = action?.payload?.data;
    });
    builder.addCase(getStudies.fulfilled, (state, action) => {
      state.studies = action.payload.data;
    });
    builder.addCase(getArrivals.fulfilled, (state, action) => {
      state.arrivals = action.payload.data;
    });
    builder.addCase(addUniversity.fulfilled, (state, action) => {
      state.currentUniversity = action.payload.data;
      successToast('University successfully added!');
    });
    builder.addCase(filterData.fulfilled, (state, action) => {
      state.data = action.payload.filterUniversities;
    });
    builder.addCase(filterNationalities.fulfilled, (state, action) => {
      if (state.stats.generalStats !== undefined) {
        state.stats.generalStats.nationalities = action.payload.filterNationalities;
      }
    });
    builder.addCase(filterScores.fulfilled, (state, action) => {
      if (state.stats.goinScoreStats !== undefined) {
        state.stats.goinScoreStats.goinScoreCollection = action.payload.filterScores;
      }
    });
  },
});

export const {
  setCurrentUniversity,
} = universitiesSlice.actions;

export default universitiesSlice.reducer;
